<template>
  <div>
    <div v-if="this.isCafeListLoading === true && this.isCafeListError === false">Пожалуйста, подождите...</div>
    <div v-else-if="this.cafeListResult === false || this.isCafeListError === true">
      {{ this.cafeListErrorMessage }}
    </div>
    <div v-else-if="this.isCafeListError === false && this.isCafeListLoading === false">
      <v-row class="mt-6">
        <v-col>
          <CafeCreateDialog v-if="checkPermission([128])" />
          <v-data-table :headers="headers" :items="cafes" :loading="isCafeListLoading" disable-pagination hide-default-footer>
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <!-- {{ cafe }} -->
                  <v-img v-if="item.image !== null" max-width="80" max-height="80" :src="url() + item.image.path" class="ma-4">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0 align-center jystify-ceter">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </td>
                <td>{{ item.nameRu }}</td>
                <td>
                  {{ getStationsList(item.stations).toString().replace('[', '').replace(']', '').trim() }}
                </td>
                <td>
                  <v-btn :to="`/control/cafes/${item.token}`" color="red" text>
                    <v-icon left>mdi-pencil-outline</v-icon>
                    Перейти
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import config from '@/config/config';
import CafeCreateDialog from '@/components/control/CafeCreateDialog';

export default {
  data() {
    return {};
  },
  components: { CafeCreateDialog },
  computed: {
    cafes() {
      let cafesArray = [];
      if (this.cafeList !== undefined && this.cafeList !== null && this.cafeList.length > 0) {
        this.cafeList.forEach((cafe) => {
          cafesArray.push(cafe);
        });
      }
      return cafesArray;
    },
    headers() {
      return [
        {
          text: '',
          value: 'image',
          sortable: false
        },
        {
          text: 'Название',
          value: 'nameRu'
        },
        {
          text: 'Возкалы',
          value: 'stations'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ];
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('admin', ['cafeList']),
    ...mapGetters('admin', ['isCafeListLoading']),
    ...mapGetters('admin', ['cafeListResult']),
    ...mapGetters('admin', ['isCafeListError']),
    ...mapGetters('admin', ['cafeListErrorMessage'])
  },

  methods: {
    url() {
      return window.location.origin + '/';
    },
    getStationsList(stations) {
      return stations.map((station) => '\n' + station.nameExpFullRu);
    },
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    }
  }
};
</script>
<style scoped>
.v-image,
video {
  border-radius: 10px;
}
</style>
