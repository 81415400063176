<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <template v-slot:activator="{ on }">
        <v-btn color="red" text v-on="on"> <v-icon left>mdi-plus</v-icon>Добавить кафе</v-btn>
      </template>
      <v-card>
        <v-form ref="form" @submit.prevent="checkInput">
          <v-card-title> Создание кафе </v-card-title>
          <v-card-text>
            <span>Название</span>
            <v-text-field v-model="editedItem.nameRu" background-color="white" class="mt-2" dense outlined> </v-text-field>
            <span>Адрес</span>
            <v-text-field v-model="editedItem.addressRu" background-color="white" class="mt-2" dense outlined> </v-text-field>
            <span>Агенство</span>
            <v-text-field v-model="editedItem.agentName" background-color="white" class="mt-2" dense outlined> </v-text-field>
            <span>ИНН</span>
            <v-text-field v-model="editedItem.agentInn" background-color="white" class="mt-2" dense outlined> </v-text-field>
            <span>Телефон</span>
            <v-text-field v-model="editedItem.agentPhone" background-color="white" class="mt-2" dense outlined> </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
            <v-btn :loading="loading" type="submit" color="red" depressed> Добавить </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Notification from '@/components/Notification';

export default {
  data() {
    return {
      editedItem: {
        addressRu: '',
        agentInn: '',
        agentName: '',
        agentPhone: ''
      },
      dialog: false,
      loading: false
    };
  },
  components: {
    Notification
  },
  methods: {
    checkInput() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.submitData();
      }
    },
    submitData() {
      this.addCafe(this.editedItem)
        .then(() => {
          this.updateCafesList();
          this.closeDialog;
          this.showNotification('Кафе успешно создано', 'success', '2000', 'mdi-check');
        })
        .catch(() => {
          this.updateCafesList();
          this.closeDialog();
          this.showNotification('Возникла ошибка', 'error', '2000', 'mdi-check');
        });
    },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.editedItem.addressRu = '';
      this.editedItem.agentInn = '';
      this.editedItem.agentName = '';
      this.editedItem.agentPhone = '';
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    updateCafesList() {
      this.getAllCafesList();
    },
    ...mapActions('admin', ['addCafe']),
    ...mapActions('notification', ['showSnack']),
    ...mapActions('admin', ['getAllCafesList'])
  }
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
